<template>
  <v-main>
    <span style="padding-left: 1%">
          <v-icon

              color="green darken-2"
          >
      mdi-android
    </v-icon>
      <span style="font-size: small; margin-left: 1%">Aisino_v: {{apkData.aisino.version}} | Other_v: {{apkData.other.version}}</span>
    </span>
    <v-container fluid>

      <div v-if="settingComponents[0].isVisible">
        <DevicesSettingsPage></DevicesSettingsPage>
      </div>
      <div v-if="settingComponents[1].isVisible">
        <Locations :avalibleLocations="availableData.avalibleLocations" v-model="tmp"></Locations>
      </div>
      <div v-if="settingComponents[2].isVisible">
        <CashiersSettingsPage></CashiersSettingsPage>
      </div>


      <v-speed-dial
          class="v-speed-dial speed-dial-btn"
          :fixed="fab.fixed"
          :absolute="fab.absolute"
          v-model="fab.isOpen"
          :bottom="fab.bottom"
          :right="fab.right"
          :top="fab.top"
          :left="fab.left"
          :direction="fab.direction"
          :open-on-hover="fab.hover"
          :transition="fab.transition"
      >

        <template v-slot:activator>
          <v-btn
              v-model="fab.isOpen"
              color="blue accent-4"
              dark
              fab
          >
            <v-icon v-if="fab.isOpen">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                dark
                small
                color="pink lighten-1"
                v-bind="attrs"
                v-on="on"
                @click="createNewDevice = true"
            >
              <v-icon>mdi-cellphone</v-icon>
            </v-btn>
          </template>
          <span>Створити Девайс</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                dark
                small
                color="green"
                v-bind="attrs"
                v-on="on"
                @click="createNewCashier = true"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити касира</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                dark
                small
                color="blue darken-2"
                v-bind="attrs"
                v-on="on"
                @click="createNewVenue = true"
            >
              <v-icon>mdi-hospital-building</v-icon>
            </v-btn>
          </template>
          <span>Створити локацію</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                dark
                small
                color="teal lighten-2"
                v-bind="attrs"
                v-on="on"
                @click="updApk = true"
            >
              <v-icon>mdi-cloud-upload</v-icon>
            </v-btn>
          </template>
          <span>Завантажити apk</span>
        </v-tooltip>


      </v-speed-dial>


      <CreateNewDevice :avalibleLocations="availableData.avalibleLocations" v-model="createNewDevice"></CreateNewDevice>
      <CreateNewVenue v-model="createNewVenue"></CreateNewVenue>
      <CreateNewCashier :avalibleLocations="availableData.avalibleLocations"
                        v-model="createNewCashier"></CreateNewCashier>
      <UploadApk v-model="updApk"></UploadApk>


    </v-container>
    <v-bottom-navigation
        value="1"
        color="teal"
        app
        grow
        shift
    >
      <v-btn
          v-for="(route, key) in routes"
          @click="togleComponents(route.to)"
          :key="'route' + key"
          min-width="48"
          class="px-0"

      >
        <span v-if="$vuetify.breakpoint.smAndUp">{{ route.name }} </span>
        <v-icon>{{ route.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-main>
</template>

<script>
import DevicesSettingsPage from "@/components/DevicesSettingsPage";
import Locations from "@/components/LocationsSettingsPage";
import CashiersSettingsPage from "@/components/CashiersSettingsPage";

import CreateNewDevice from "@/components/CreateNewDevice"
import CreateNewVenue from "@/components/CreateNewVenue"
import CreateNewCashier from '@/components/CreateNewCashier'
import UploadApk from "@/components/UploadApk";
import apiService from "@/apiServices/apiService";

export default {
  name: "SettingsPage",
  components: {
    CreateNewDevice,
    CreateNewVenue,
    CreateNewCashier,
    DevicesSettingsPage,
    Locations,
    CashiersSettingsPage,
    UploadApk
  },


  data: () => ({

    tmp: false,
    device: {},
    fab: {
      isOpen: false,
      direction: 'top',
      absolute: true,
      fixed: true,
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
    },
    changeDeviceDialog: false,
    createNewDevice: false,
    createNewVenue: false,
    createNewCashier: false,
    updApk: false,
    apkData: {aisino: {version: ' n/a'}, other : {version: ' n/a'}},
    settingComponents: [
      {name: 'devices', isVisible: true},
      {name: 'locations', isVisible: false},
      {name: 'cashiers', isVisible: false},
    ],
    availableData: {
      avalibleLocations: [],
      avalibleCashiers: [],
    },

    routes: [
      {icon: 'mdi-cellphone', name: 'Девайси', to: 'devices'},
      {icon: 'mdi-hospital-building', name: 'Зали', to: 'locations'},
      {icon: 'mdi-account', name: 'Касири', to: 'cashiers'},
    ],
  }),

  mounted() {
    this.getLocations();
    this.getApkData()
  },
  methods: {

    async getApkData(){
      try{
        const apkData = await apiService.getApkVersion();
        if(apkData['success']){
          this.apkData = apkData['versions'];
        }

      }catch (e) {
        console.log('Can not take APKs versions')
      }

    },
    togleComponents(name) {
      this.tmp = !this.tmp;
      this.settingComponents.forEach(component => {
        if (name == component.name) {
          component.isVisible = true;
        } else {
          component.isVisible = false;
        }
      })
    },
    openChangeDeviceDialog(device) {
      this.device = device;
      this.changeDeviceDialog = true;

    },
    async getLocations() {
      try {
        console.log('get locations....')
        const locations = await apiService.getLocations();
        console.log('locations --->>> ', locations)
        if ('msg' in locations) return false;
        if (locations.success) {
          this.availableData.avalibleLocations = locations.locations;
        } else {
          this.$store.commit('setSnackMessage', {
            message: `❕Проблема отримання локацій️\n${locations.error}`,
            isError: true
          })
          // alert(`Проблема отримання локацій ❗️\n${locations.error}`);
        }
      } catch (e) {
        this.$store.commit('setSnackMessage', {
          message: `❕${e}`,
          isError: true
        })
      }

    },
  },
}
</script>

<style scoped>

.add-btn {
  transition: transform .2s;
}

.add-btn:hover {
  transform: scale(1.5) rotate(180deg);
}

.v-speed-dial {
  position: absolute;
}
.speed-dial-btn {
  z-index: 200;
  position: fixed
}


@media (max-width: 768px) {
  .speed-dial-btn {
    bottom: 65px !important;
  }
}

.v-btn--floating {
  position: relative;
}

</style>
