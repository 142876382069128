import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import MainPage from "@/views/MainPage";
import Login from '@/views/Login'
import SettingsPage from "@/views/SettingsPage";
import SpammerPage from "@/views/SpammerPage.vue";
import DesignSettings from "@/views/DesignSettings.vue";
import PageNotFound from "@/components/PageNotFound";

Vue.use(VueRouter)
const routes = [
  {
    path: '/mainpage',
    name: 'MainPage',
    component: MainPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'SettingsPage',
    component: SettingsPage,
    meta: { requiresAdmin: true }
  },
  {
    path: '/spam',
    name: 'SpammerPage',
    component: SpammerPage,
    meta: { requiresSpammerOrAdmin: true }
  },
  {
    path: '/kioskDesign',
    name: 'DesignSettings',
    component: DesignSettings,
    meta: { requiresSpammerOrAdmin: true }
  },
  {
    path: '/',
    name: "Login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "*",
    name: 'NotFound',
    component: PageNotFound,
    meta: { guest: true, hideNavbar: true }
  }

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


//https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated && store.getters.checkUserRole !== 'SPAMMER') {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresSpammerOrAdmin)) {
    if (store.getters.isAuthenticated && (store.getters.checkUserRole === 'ADMIN' || store.getters.checkUserRole === 'SPAMMER')) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters.isAuthenticated && store.getters.checkUserRole === 'ADMIN') {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      console.log('user in route NEXT -> ', store.getters.isAuthenticated)
      next("/mainpage");
      return;
    }
    next();
  } else {
    next();
  }
});


export default router
