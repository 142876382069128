<template>
  <v-container fluid>
    <v-col v-if="isErrorGetData.state" md="12" class="pa-9" style="text-align: center">
      <h1>{{isErrorGetData.msg}}</h1>
      <br>
      <h3>{{isErrorGetData.errMsg}}</h3>
    </v-col>
    <v-progress-linear v-if="loading"
                       :active="loading"
                       :indeterminate="loading"
                       height="6px"
                       style="margin-top: -10px"
    ></v-progress-linear>
    <v-row v-if="loading">
      <v-col
          style="margin-top: 20px"
          v-for="index in 8"
          :key="index"
          cols="6"
          md="4">
        <v-skeleton-loader
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="margin-top: 20px"
             v-for="(location, index) in locations"
             :key="index"
             cols="12"
             lg="4"
             md="6"
             sm="12"
             xs="12"
      >
        <v-card
            class="pa-2"
            tile
            elevation="9"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                ID: {{ location.locationId }}
              </div>
              <v-list-item-title class="text-h5 mb-1">
                <v-icon color="primary">mdi-hospital-building</v-icon>
                <v-spacer></v-spacer>
                <span>{{ location.locationName }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn
                color="primary"
                rounded
                text
                @click="removeLocation(location.locationId)"


            >
              Видалити
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-col>
    </v-row>




  </v-container>
</template>

<script>
import apiService from "@/apiServices/apiService";
import { mapState } from 'vuex';

export default {
  name: "LocationsSettingsPage",
  props: {
    value: Boolean,
  },
  data: () => ({
    loading: true,
    locations: [],
    isErrorGetData: {state: false, msg: 'Дані відсутні 🥺', errMsg: ''},


  }),
  methods: {
    async getLocations() {
      try {
        this.isErrorGetData.state = false;
        this.loading = true;
        const locations = await apiService.getLocations();
        if('msg' in locations) return false;
        this.loading = false;
        if (locations.success) {
          this.locations = locations.locations;
        } else {
          this.isErrorGetData.state = true;
          this.isErrorGetData.errMsg = locations.error;
          this.$store.commit('setSnackMessage', {
            message: `Проблема отримання локацій \n${locations.error}`,
            isError: true
          })
        }
      } catch (e) {
        this.loading = false;
        this.isErrorGetData.state = true;
        this.isErrorGetData.errMsg = e;
        this.$store.commit('setSnackMessage', {
          message: `Проблема отримання локацій \n${e}`,
          isError: true
        })
      }

    },
    async removeLocation(location) {
      const confirm = window.confirm('⁉️ Впевненні️?');
      if (confirm) {
        this.loading = true;
        try {
          const deleteResponse = await apiService.deleteLocation(location);
          this.loading = false;
          if (deleteResponse.success) {
            this.$store.commit('setSnackMessage', {
              message: 'Локація успішно видалена',
              icon: 'mdi-check',
            })
          } else {
            this.$store.commit('setSnackMessage', {
              message: `Помилка видалення ❗️\n\n${deleteResponse.error}`,
              isError: true
            })
          }
        } catch (e) {
          this.$store.commit('setSnackMessage', {
            message: `Помилка видалення️ \n\n${e}`,
            isError: true
          })
          this.loading = false;
        }
      } else {
        return false
      }


    }
  },
  mounted() {
    this.getLocations();
  },
  computed: {
    loadState: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['isNedUpdLocationsList']),


  },
  watch: {

  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'changeUpdLocationsList') {
        console.log('in subscr -> ', mutation.payload, state.updater.nedUpdLocations)
        this.getLocations();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },


}
</script>

<style scoped>

</style>
