<template>
  <v-main>
    <v-container fluid>
      <change-kiosk-background v-if="isKioskBgEditorVisible" :device-id="deviceToEdit.deviceId"
                               :firebase-token="deviceToEdit.firebaseToken"
                               :is-dialog-visible="isKioskBgEditorVisible"
                               @close-changer="toggleShowEditKioskBg"></change-kiosk-background>
      <v-progress-linear v-if="loading"
                         :active="loading"
                         :indeterminate="loading"
                         height="6px"
      ></v-progress-linear>
      <v-row v-if="loading">
        <v-col
            style="margin-top: 20px"
            v-for="index in 8"
            :key="index"
            cols="6"
            md="4">
          <v-skeleton-loader
              type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row>
        <v-col style="margin-top: 20px"
               v-for="(device) in kioskDevices"
               :key="device.deviceId"
               cols="12"
               lg="4"
               md="6"
               sm="12"
               xs="12"
        >
          <v-card
              class="pa-2"
              tile
              elevation="9"
          >
            <v-app-bar
                flat

            >
              <v-toolbar-title class="text-h5 mb-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">

                    <v-avatar
                        v-if="String(device.type).toUpperCase() === 'KIOSK' || String(device.type).toUpperCase() === 'POSTER'"
                        tile
                        size="25"
                    >

                      <img v-if="String(device.type).toUpperCase() === 'KIOSK'"
                           :src="require(`../assets/kiosk_${device.isActive ? 'active' : 'noactive'}.png`)"
                           alt="kiosk"

                      >

                      <img v-else
                           :src="require(`../assets/pos_${device.isActive ? 'active' : 'noactive'}.png`)"
                           alt="poster"
                      >
                    </v-avatar>
                    <v-icon v-else
                            :color="device.isActive ? 'success' : 'pink'"
                            dark
                            v-bind="attrs"
                            v-on="on"
                    >
                      mdi-cellphone
                    </v-icon>

                  </template>
                  <span>{{ device.isActive ? 'Активний' : 'Вимкнутий' }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                            <span class="adaptive-text"
                                v-bind="attrs"
                                v-on="on">
                              ID: {{ device.deviceId }}
                            </span>
                  </template>
                  <span >{{ device.comment }}</span>
                </v-tooltip>
                <copy-to-clipboard-component hint-text="id" :value="device.deviceId"></copy-to-clipboard-component>
              </v-toolbar-title>

              <v-spacer></v-spacer>

            </v-app-bar>

            <v-list-item-group>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-hospital-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span>{{ device.locationName }}</span>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="info">mdi-cogs</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span>
                    FW: <b>{{ device.firmwareVersion ?? 'N/A' }}</b>  |  Conf: <b>{{device.configVersion ?? 'N/A' }}</b>
                  </span>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="info">mdi-comment-processing-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span><small>{{ device.comment }}</small></span>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  rounded
                  text
                  @click="changeKioskBg(device)"
              >
                Змінити
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </v-main>

</template>

<script>


import apiService from "@/apiServices/apiService";
import CopyToClipboardComponent from "@/components/CopyToClipboardComponent.vue";
import ChangeKioskBackground from "@/components/ChangeKioskBackground.vue";

export default {
  name: "DesignSettings",
  components: {ChangeKioskBackground, CopyToClipboardComponent},
  data: () => ({
    isKioskBgEditorVisible: false,
    isVirtualVisible: false,
    loading: true,
    device: {type: ''},
    activeFilter: 'KIOSK',
    kioskDevices: [],
    deviceToEdit: null

  }),

  methods: {

    toggleShowEditKioskBg(device) {
      console.log('[i] ToggleShowEditKioskBg device: ', device)
      this.isKioskBgEditorVisible = !this.isKioskBgEditorVisible;
      this.deviceToEdit = null;
    },

    changeKioskBg(device) {
      console.log('[i] ChangeKioskBg device: ', device)
      this.isKioskBgEditorVisible = true;
      this.deviceToEdit = device;
    },

    async getDevices() {
      this.loading = true;
      try {
        let devices = await apiService.getDevices();
        if ('msg' in devices) return false;
        this.loading = false;
        if (devices.success) {
          this.kioskDevices = devices.devices.filter(device => device.type === 'KIOSK' && !device.deviceId.includes('virtual'));
          console.log('[i] Kiosk devices: ', this.kioskDevices)
        } else {
          this.$store.commit('setSnackMessage', {
            message: `Проблема отримання даних️\n${devices.error}`,
            isError: true
          })
        }
      } catch (e) {
        this.$store.commit('setSnackMessage', {
          message: `Проблема отримання даних️\n${e}`,
          isError: true
        })
        this.loading = false;
      }

    },
  },

  async mounted() {
    console.log('DesignSettings mounted')
    await this.getDevices();

  },
}
</script>

<style scoped>

</style>
