<template>
  <v-container fluid>
    <v-col v-if="isErrorGetData.state" md="12" class="pa-9" style="text-align: center">
      <h1>{{isErrorGetData.msg}}</h1>
      <br>
      <h3>{{isErrorGetData.errMsg}}</h3>
    </v-col>
    <v-progress-linear v-if="loading"
                       :active="loading"
                       :indeterminate="loading"
                       height="6px"
                       style="margin-top: -10px"
    ></v-progress-linear>
    <v-row v-if="loading">
      <v-col
          style="margin-top: 20px"
          v-for="index in 8"
          :key="index"
          cols="6"
          md="4">
        <v-skeleton-loader
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>


    <v-row>
      <v-col style="margin-top: 20px"
             v-for="(cashier, index) in cashiers"
             :key="index"
             cols="12"
             lg="4"
             md="6"
             sm="12"
             xs="12"
      >
        <v-card
            class="pa-2"
            tile
            elevation="9"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                ID: {{ cashier.cashierId }}
              </div>
              <v-list-item-title class="text-h5 mb-1">
                <v-icon color="primary">mdi-account-circle</v-icon>
                <v-spacer></v-spacer>
                <span class="adaptive-text-scoped">{{ cashier.cashierName }}</span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <span class="text-overline">locationId: <b>{{cashier.locationId}}</b></span>
              <span class="text-overline">deviceId: <b>{{cashier.deviceId}}</b></span>
              <v-spacer></v-spacer>
              <v-btn v-if="!cashier.showPin" style="margin-top: 20px"
                     @click="cashier.showPin = !cashier.showPin"
                     icon color="blue">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <span v-else class="text-overline" style="margin-top: 20px; cursor: pointer">
                <span @click="cashier.showPin = !cashier.showPin">pin: {{ cashier.pin }}</span>
                <copy-to-clipboard-component hint-text="PIN" :value="cashier.pin"></copy-to-clipboard-component>
              </span>

            </v-list-item-action>
          </v-list-item>
          <v-card-actions>
            <v-btn
                color="primary"
                rounded
                text
                @click="changeCashier(cashier)"

            >
              Змінити
            </v-btn>
            <v-btn
                color="primary"
                rounded
                text

            >
              Видалити
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-col>
    </v-row>

    <ChangeCashierDialog :cashier="cashier" v-model="cashierSettingDialog"></ChangeCashierDialog>


  </v-container>
</template>

<script>
import apiService from "@/apiServices/apiService";
import { mapState } from 'vuex';
import ChangeCashierDialog from "@/components/ChangeCashierDialog";
import CopyToClipboardComponent from "@/components/CopyToClipboardComponent.vue";

export default {
  name: "CashiersSettingsPage",
  components: {CopyToClipboardComponent, ChangeCashierDialog},
  data: () => ({
    loading: true,
    cashiers: [],
    cashier: {},
    cashierSettingDialog: false,
    isErrorGetData: {state: false, msg: 'Дані відсутні 🥺', errMsg: ''},
  }),
  methods: {
    async getCashiers() {
      try {
        this.isErrorGetData.state = false;
        this.loading = true;
        const cashiers = await apiService.getCashiers();
        if('msg' in cashiers) return false;
        this.loading = false;
        if (cashiers.success) {
          const cashiersToRender = cashiers.cashiers.map(cashier =>{
            cashier['showPin'] = false;
            return cashier
          })
          this.cashiers = cashiersToRender;
        } else {
          this.isErrorGetData.state = true;
          this.isErrorGetData.errMsg = cashiers.error;
          this.$store.commit('setSnackMessage', {
            message: `Проблема отримання локацій \n${cashiers.error}`,
            isError: true
          })
        }
      } catch (e) {
        this.loading = false;
        this.isErrorGetData.state = true;
        this.isErrorGetData.errMsg = e;
        this.$store.commit('setSnackMessage', {
          message: `Проблема отримання локацій ${e}`,
          isError: true
        })
      }

    },

    changeCashier(cashier){
      this.cashier = cashier;
      this.cashierSettingDialog = true;
    },

  },
  mounted() {
    this.getCashiers();
  },

  computed:{
    ...mapState(['isNedUpdCashiersList']),
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'changeUpdCashiersList') {
        this.getCashiers();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
}
</script>

<style scoped>
.adaptive-text-scoped {
  font-size: 1em; /* Базовий розмір */
}

@media (max-width: 768px) {
  .adaptive-text-scoped {
    font-size: 0.7em; /* Зменшуємо на 20% */
  }
}

</style>
